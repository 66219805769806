import React from 'react';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import '../styles/index.css';
import { Link } from 'gatsby';
import HtmlHead from '../components/html-head';

const ThankyouPage: React.FC = () => {
    return (
        <>
            <HtmlHead
                title={"Thank You | Aptedge"}
                metaDescription={"Aptedge Thank You Page"}
                canonicalLink={"https://www.aptedge.io/thank-you/"}
                openGraphImage={""}
            />
            <Navbar />
            <section className="not-found-section">
                <div className="not-found-wrapper">
                    <div>
                        <div className="not-found-text-wrapper thankyou">
                            <p className="text-2xl dark-gray bold">Thank you for your submission.</p>
                            <p className="text-xl dark-gray semi-bold">We will get back to you.</p>
                        </div>
                        <Link to="/" className="btn back">
                            Home
                        </Link>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ThankyouPage;
